import quickViewContext from '@/contexts/quickViewContext';
import wishlistContext from '@/contexts/wishlistContext';
import { useCart } from '@/kytesoft-client/features/useCart';
import { usePrice } from '@/kytesoft-client/features/usePrice';
import { useWishlist } from '@/kytesoft-client/features/useWishlist';
import { Rating } from '@components/compound/Rating';
import { Tooltip } from '@components/compound/Tooltip';
import { Badge } from '@components/primitive/Badge';
import { Button } from '@components/primitive/Button';
import { KaImage } from '@components/primitive/Image';
import { Link } from '@components/primitive/Link';
import useProductPrice from '@hooks/useProductPrice';
import { IProduct } from '@interfaces/product';
import Skeleton from '@mui/material/Skeleton';
import { routes } from '@utils/routes';
import classNames from 'classnames';
import { get, isEmpty, isEqual, isNumber } from 'lodash';
import { FC, useContext } from 'react';
import { toast } from 'react-toastify';
import productCardStyles from '@scss/components/product-card.scss';

export interface IProductCardProps {
  data?: IProduct;
  isLoading?: boolean;
}

export const ProductCard: FC<IProductCardProps> = ({ data, isLoading = false }) => {
  const { handleOpenModal } = useContext(quickViewContext);
  const {
    handleOpen: handleOpenWishlist,
    wishlists,
    addToWishlist: addToWishlistContext,
    removeFromWishlist: removeFromWishlistContext,
    isInWishlist,
  } = useContext(wishlistContext);
  const { addItemToCart } = useCart();
  const { formatPrice } = usePrice();
  const { price, hasSale, discountRate, salePrice, minPrice, maxPrice } = useProductPrice(data);
  const hasAttribute = !isEmpty(get(data, 'attributes', undefined));

  const { addItemToWishlist, removeItemFromWishlist } = useWishlist({
    messages: {
      addToWishlistUnauthorized: 'Bạn cần đăng nhập để thêm sản phẩm vào danh sách yêu thích',
    },
    // @ts-ignore
    onAddWishlistError: (message) =>
      toast.error(message || 'Thêm vào danh sách yêu thích thất bại'),
    onAddWishlistSuccess: () => {
      handleOpenWishlist(data);
      addToWishlistContext(data);
      toast.success('Thêm vào danh sách yêu thích này');
    },
    // @ts-ignore
    onDeleteWishlistError: (message) =>
      toast.error(message || 'Xóa khỏi danh sách yêu thích thất bại'),
    onDeleteWishlistSuccess: () => {
      toast.success('Xóa khỏi danh sách yêu thích thành công');
      removeFromWishlistContext(data?.id);
    },
  });

  const handleAddToCart = () => {
    if (hasAttribute) return handleOpenModal(data);
    else {
      if (!data?.quantity || data?.quantity < 1) return toast.error('Sản phẩm đã hết hàng');

      addItemToCart({
        slug: data?.slug,
        quantity: 1,
        attributeId: undefined,
      })
        .then(() => toast.success('Thêm vào giỏ hàng thành công'))
        .catch(() => toast.error('Thêm vào giỏ hàng thất bại'));
    }
  };

  const renderPrice = () => {
    if (isNumber(price) && price > 0)
      return (
        <p className="price">
          {formatPrice(hasSale ? salePrice : price)}đ&nbsp;
          {hasSale && (
            <del>
              <small>{formatPrice(price)}đ</small>
            </del>
          )}
        </p>
      );

    return (
      <p className="price">
        {formatPrice(minPrice)}đ&nbsp;
        {!hasSale && isEqual(minPrice, maxPrice) ? null : (
          <span>
            -&nbsp;
            {formatPrice(maxPrice)}đ
          </span>
        )}
        {hasSale && (
          <del>
            <small>{formatPrice(salePrice)}đ</small>
          </del>
        )}
      </p>
    );
  };

  return (
    <>
      <style jsx>{productCardStyles}</style>
      <div className={classNames('ks-product-card', { '-loading': isLoading })}>
        <div className="thumbnail">
          {!isLoading ? (
            <>
              <Link
                title={data?.name || 'product'}
                href={{
                  pathname: routes.PRODUCT,
                  query: {
                    slug: data?.slug,
                  },
                }}
                className="images"
              >
                <KaImage
                  src={get(data, 'images[0]', '')}
                  alt={get(data, 'images[0]', '')}
                  objectFit="cover"
                  className="image -main"
                  ratio="square"
                  useNextImage
                  sizes="100%"
                  width={280}
                  height={280}
                />
                <KaImage
                  src={get(data, 'images[1]', get(data, 'images[0]', ''))}
                  alt={get(data, 'images[1]', get(data, 'images[0]', ''))}
                  objectFit="cover"
                  className="image -secondary"
                  useNextImage
                  width={280}
                  height={280}
                />
              </Link>

              {hasSale && <Badge label={`-${discountRate}%`} className="badge" />}

              <div className="actions">
                <Button
                  className="button"
                  iconOnly
                  variant="contained"
                  color="light"
                  onClick={() =>
                    isInWishlist(data?.id)
                      ? removeItemFromWishlist(data?.id)
                      : addItemToWishlist(data?.id)
                  }
                >
                  <Tooltip title="Yêu thích" placement="left" arrow>
                    <span className={classNames('icon', { '-active': isInWishlist(data?.id) })}>
                      <i
                        className={classNames(
                          'fa-heart icon',
                          isInWishlist(data?.id) ? 'fa-solid' : 'fa-light',
                        )}
                      />
                    </span>
                  </Tooltip>
                </Button>

                <Button
                  onClick={() => handleOpenModal(data)}
                  className="button"
                  iconOnly
                  variant="contained"
                  color="light"
                >
                  <Tooltip title="Xem ngay" placement="left" arrow>
                    <span className="icon">
                      <i className="fa-light fa-magnifying-glass-plus" />
                    </span>
                  </Tooltip>
                </Button>
              </div>
            </>
          ) : (
            <Skeleton width="100%" height="100%" variant="rectangular" animation="wave" />
          )}
        </div>

        <div className="content">
          {!isLoading && (
            <>
              <div className="rating">
                <Rating value={Number(data?.rating) || 5} readOnly />
              </div>

              <p className="name">
                <Link
                  title={data?.name || 'product'}
                  className="link"
                  href={{
                    pathname: routes.PRODUCT,
                    query: {
                      slug: data?.slug,
                    },
                  }}
                >
                  {data?.name}
                </Link>
              </p>

              <div className="footer">
                {renderPrice()}

                <div className="buy">
                  <Button
                    className="button"
                    iconOnly
                    variant="outlined"
                    noBorder
                    onClick={handleAddToCart}
                  >
                    <Tooltip title="Mua ngay" placement="top" arrow>
                      <i className="fa-light fa-bag-shopping icon" />
                    </Tooltip>
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
