import productServices from '@/kytesoft-client/services/productServices';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { ceil, get, pickBy, trim } from 'lodash';
import { PAGE_SIZE } from '../constants';

export const getProductsQueryConfig = ({
  page = 1,
  limit = PAGE_SIZE,
  keyword,
  isPopular,
  isBestSelling,
  categoryId,
  category,
  options = {},
  queryKey = {},
  ...rest
} = {}) => {
  const params = {
    page,
    limit,
    q: trim(keyword),
    isPopular,
    isBestSelling,
    categoryId,
    category,
  };
  const originalParams = pickBy(params, (val) => !!val);

  return {
    queryKey: ['/products', { ...originalParams, ...queryKey }],
    queryFn: () => productServices.getProducts(originalParams),
    options: { retry: 1, ...options },
    ...rest,
  };
};

export const getProductsInfiniteQueryConfig = ({ limit, ...rest }) => ({
  queryKey: ['/products', { limit, ...rest }],
  queryFn: async ({ pageParam }) => {
    const page = Number(pageParam) || 1;

    const originalParams = pickBy({ limit, page }, (val) => !!val);

    const response = await productServices.getProducts({ limit, page, ...originalParams });

    return {
      data: get(response, 'items'),
      paginationInfo: {
        nextPage: page + 1,
        hasNextPage: page < ceil(get(response, 'total') / limit),
      },
    };
  },
  getNextPageParam: ({ paginationInfo: { nextPage, hasNextPage } }) =>
    hasNextPage ? nextPage : false,
});

export const useProductsQuery = ({
  page,
  limit,
  keyword,
  isPopular,
  isBestSelling,
  categoryId,
  category,
  options,
  ...rest
} = {}) => {
  return useQuery(
    getProductsQueryConfig({
      page,
      limit,
      keyword,
      isPopular,
      isBestSelling,
      categoryId,
      category,
      options,
      ...rest,
    }),
  );
};

export const useProductsInfiniteQuery = ({ limit = 8, ...rest } = {}) =>
  useInfiniteQuery(getProductsInfiniteQueryConfig({ limit, ...rest }));

export const prefetchProductsQuery = async (queryClient, options) => {
  await queryClient.prefetchQuery(getProductsQueryConfig(options));
};

export const prefetchProductsInfiniteQuery = async (queryClient, options) => {
  await queryClient.prefetchInfiniteQuery(getProductsInfiniteQueryConfig(options));
};
