import MuiTooltip from '@mui/material/Tooltip';
import classNames from 'classnames';
import { FC, ReactElement } from 'react';
import tooltipStyles from '@scss/components/tooltip.scss';

interface ITooltipProps {
  children: ReactElement;
  title: string;
  enterDelay?: number;
  leaveDelay?: number;
  arrow?: boolean;
  placement?:
    | 'top-start'
    | 'top'
    | 'top-end'
    | 'left-start'
    | 'left'
    | 'left-end'
    | 'right-start'
    | 'right'
    | 'right-end'
    | 'bottom-start'
    | 'bottom'
    | 'bottom-end';
  tooltipClassName?: string;
  popperClassName?: string;
  arrowClassName?: string;
  className?: string;
}

export const Tooltip: FC<ITooltipProps> = ({
  className,
  children,
  title,
  enterDelay,
  leaveDelay,
  arrow,
  placement,
  tooltipClassName,
  popperClassName,
  arrowClassName,
}) => {
  return (
    <>
      <style jsx>{tooltipStyles}</style>
      <MuiTooltip
        title={title}
        className={classNames('ks-tooltip', className)}
        componentsProps={{
          tooltip: {
            className: classNames('ks-tooltip-content', `-${placement}`, tooltipClassName),
          },
          popper: {
            className: classNames('ks-tooltip-popper', popperClassName),
          },
          arrow: {
            className: classNames('ks-tooltip-arrow', arrowClassName),
          },
        }}
        enterDelay={enterDelay}
        leaveDelay={leaveDelay}
        arrow={arrow}
        placement={placement}
      >
        {children}
      </MuiTooltip>
    </>
  );
};

Tooltip.defaultProps = {
  enterDelay: 0,
  leaveDelay: 0,
  arrow: true,
  placement: 'top',
};
